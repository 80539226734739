<template>
	<div class="banner" ref="banner" :style="{'bottom': -parallaxPos + 'px'}">
		<div class="container">
			<div class="banner__left">
				<h1 class="banner--title" v-html="content.title" v-if="titleIsH1"></h1>
				<p class="banner--title" v-html="content.title" v-else></p>
				<div class="banner--desc" v-html="content.desc"></div>
			</div>
			<div class="banner__right" ref="bannerImages">
				<img :src="require('@/assets/img_new/banner/banner6.png')" alt="">
				<img :src="require('@/assets/img_new/banner/banner2.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner3.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner4.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner7.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner4.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner3.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner7.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner4.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner2.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner7.png')" alt="" style="opacity: 0">
				<img :src="require('@/assets/img_new/banner/banner4.png')" alt="" style="opacity: 0">
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Banner'
</style>

<script>
	export default {
		props: ['content', 'titleIsH1'],
		data: () => ({
			bannerAnimationInterval: null,
			bannerAnimationIterator : 0,
			images: [],
			imagesCount: 0,
			parallaxPos: 0,
			elementHeight: 0,
			elementPosTop: 0,
			sectionHeight: 0,
			sectionPosTop: 0
		}),
		mounted() {
			var comp = this;

			this.animateBanner();

			this.resetSizes();
			this.parallax();

			window.addEventListener('scroll', function(){
				comp.parallax();
			});

			window.addEventListener('resize', function(){
				comp.resetSizes();
				comp.parallax();
			});
		},
		methods: {
			animateBanner: function(){
				this.images = this.$refs["bannerImages"].getElementsByTagName("img");
				this.imagesCount = this.images.length;
				
				this.animateBannerStartInterval();
			},
			animateBannerStartInterval: function(){
				var comp = this;

				comp.bannerAnimationInterval = setInterval(function(){
					comp.images[comp.bannerAnimationIterator % comp.imagesCount].style.opacity = 0;

					comp.images[(comp.bannerAnimationIterator + 1) % comp.imagesCount].style.opacity = 1;

					if((comp.bannerAnimationIterator + 1) % comp.imagesCount == 0){
						comp.animateBannerClearInterval();
					}
					
					comp.bannerAnimationIterator++;
				}, 200);
			},
			animateBannerClearInterval: function(){
				var comp = this;

				if(comp.bannerAnimationInterval){
					clearInterval(comp.bannerAnimationInterval);
					setTimeout(function(){
						comp.animateBannerStartInterval();
					}, 1500);
				}
			},
			resetSizes: function(){
				var element = this.$refs["banner"];
				var section = this.$refs["banner"].closest('.fullPageSectionContainer');
				this.elementHeight = this.$refs["banner"].offsetHeight;
				this.elementPosTop = element.offsetTop;
				this.sectionHeight = section.offsetHeight;
				this.sectionPosTop = section.offsetTop;
			},
			parallax: function(){
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowWidth = window.innerWidth;
				var windowBottomPos = scrollTop + windowHeight;

				if(windowBottomPos > this.sectionPosTop){
					var percent = 1 - ((this.sectionPosTop + this.sectionHeight - scrollTop) / this.sectionHeight);

					this.parallaxPos = ((this.sectionPosTop + this.sectionHeight) - (this.elementPosTop + this.elementHeight) + (this.sectionPosTop - this.elementPosTop) / 2) * percent;
				} else{
					this.parallaxPos = 0;
				}
			}
		},
		components: {
		}
	}
</script>
